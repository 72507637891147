import React from "react"
import { graphql } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

class AdvertisingPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Advertising Policy" />
        <div className="col-lg-8 col-sm-12">
          <h1>Advertising Policy</h1>
          <h2>Affiliate Partners</h2>
          <p>
            <em>Is it time to</em> participates in a number of affiliate programs that change over time. We aim to
            provide our readers with the best options available to them, and to make it clear when these deals are
            being provided by a partner of ours.
          </p>
          <h2>Sponsored Posts</h2>
          <p>
            All sponsored posts are clearly marked ensuring our readers are clear on the relationships between
            author and sponsor.
          </p>
          <h2>Other</h2>
          <p>
            All posts are formed from the research and opinions of the author. No posts or content are edited by third
            parties.
          </p>
          <div>
            <p>
              If you would like to get in touch, please email us here: <Mailto email="contact@isittimeto.com"></Mailto>.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
            <MailChimp />
            <PopularPosts />
        </div>
      </Layout>
    )
  }
}

export default AdvertisingPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`